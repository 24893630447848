@keyframes linerShowHide {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes linerShowHide0-2 {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes rotateAround {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes upAndDown {
  0% {
    transform: translate(var(--transformX), var(--transformY));
  }
  50% {
    transform: translate(var(--transformX), calc(var(--transformY) + 10px));
  }
  100% {
    transform: translate(var(--transformX), var(--transformY));
  }
}

@keyframes cup-top-token-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes blinking {
  0% {
    background-image: linear-gradient(to right, rgba(67, 67, 67, 0.4), rgba(0, 0, 0, 0.4), rgba(67, 67, 67, 0.4));
    transform: translate(-48%, -50%) rotate(25.82deg) skewX(0);
  }
  10% {
    background-image: linear-gradient(to right, rgba(67, 67, 67, 0), rgba(0, 0, 0, 0), rgba(67, 67, 67, 0));
  }
  30% {
    background-image: linear-gradient(to right, rgba(67, 67, 67, 0.6), rgba(0, 0, 0, 0.6), rgba(67, 67, 67, 0.6));
    transform: translate(-48%, -50%) rotate(25.82deg) skewX(-2deg);
  }
  31% {
    background-image: linear-gradient(to right, rgba(67, 67, 67, 0.7), rgba(0, 0, 0, 0.7), rgba(67, 67, 67, 0.7));
  }
  31.5% {
    transform: translate(-48%, -50%) rotate(25.82deg) skewX(89deg);
  }
  32.1% {
    transform: translate(-48%, -50%) rotate(25.82deg) skewX(0);
  }
  33% {
    transform: translate(-48%, -50%) rotate(25.82deg) skewX(2deg);
  }
  34% {
    background-image: linear-gradient(to right, rgba(67, 67, 67, 0), rgba(0, 0, 0, 0), rgba(67, 67, 67, 0));
  }
  40% {
    background-image: linear-gradient(to right, rgba(67, 67, 67, 0), rgba(0, 0, 0, 0), rgba(67, 67, 67, 0));
  }
  45% {
    background-image: linear-gradient(to right, rgba(67, 67, 67, 0.5), rgba(0, 0, 0, 0.5), rgba(67, 67, 67, 0.5));
  }
  46% {
    background-image: linear-gradient(to right, rgba(67, 67, 67, 0), rgba(0, 0, 0, 0), rgba(67, 67, 67, 0));
  }
  54% {
    transform: translate(-48%, -50%) rotate(25.82deg) skewX(0deg);
  }
  95.1% {
    transform: translate(-48%, -50%) rotate(25.82deg) skewX(-3deg);
  }

  95.2% {
    transform: translate(-48%, -50%) rotate(25.82deg) skewX(-89deg);
  }

  95.3% {
    transform: translate(-48%, -50%) rotate(25.82deg) skewX(2deg);
  }

  100% {
    transform: translate(-48%, -50%) rotate(25.82deg) skewX(0);
    background-image: linear-gradient(to right, rgba(67, 67, 67, 0.4), rgba(0, 0, 0, 0.4), rgba(67, 67, 67, 0.4));
  }
}

@keyframes shine {
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}

@keyframes wave {
  0%,
  100% {
    transform: translate(-60%, -55%) rotateX(90deg);
  }
  50% {
    transform: translate(-40%, -45%) rotateX(0deg);
  }
}
